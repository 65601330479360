<template>
  <template v-if="components && components.length > 0">
    <component
      v-for="(component, index) in components"
      :is="component.component"
      :key="index"
      :data="component.data"
    />
  </template>
</template>

<script setup lang="ts">
// Composables
import useRenderAsyncOrganisms from '~/composables/render/asyncOrganisms/useRenderAsyncOrganisms';
import { useIceCoreGenericData } from '#imports';

// Types
import type { IGenericComponentAsync } from '#build/types/ice-core-generic-request';

const { getPageAsyncComponents } = useRenderAsyncOrganisms();
const { getGenericPageData } = useIceCoreGenericData();

let components = shallowRef<IGenericComponentAsync[] | null>([]);

components.value = await getPageAsyncComponents();

useHead({
  title: getGenericPageData.value.meta_title,
  meta: [
    { name: 'description', content: getGenericPageData.value.meta_description },
    {
      name: 'keywords',
      content: getGenericPageData.value.meta_keywords.length
        ? getGenericPageData.value.meta_keywords.join(', ')
        : undefined,
    },
  ],
});
</script>
