/**
 * @description Абстрактный класс обработчика ошибок.
 */
export abstract class AbstractErrorHandler {
  /**
   * @description Объект с предопределенными типами ошибок.
   * @type {{[p: string]: {message?: string | undefined, statusCode: number, statusMessage: string}}}
   * @protected
   */
  protected abstract errors: {
    [key: string]: { statusCode: number; statusMessage: string; message?: string };
  };

  /**
   * @description Функция возвращающая ошибку. Возвращаемый тип должен быть never, так как функция никогда не вернется
   * к своему вызывающему объекту после возникновения ошибки.
   *
   * @param {string} errorKey - Ключ ошибки, необходимо предопределить ключи различных ошибок.
   * @param {string} customStatusMessage - Переопределение заголовка ошибки.
   * @param {string} customMessage - Переопределение текста ошибки.
   * @returns {never}
   */
  public abstract throwError(
    errorKey: string,
    customStatusMessage?: string,
    customMessage?: string,
  ): never;
}
